import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React from "react";
import { css } from "styled-components/macro"; //eslint-disable-line

import RPA from "pages/RPA";
import DevSoftware from "pages/DevSoftware";
import DataAnalytics from "pages/DataAnalytics";
import PortfolioManagement from "pages/PortfolioManagement";
import BusinessIntelligence from "pages/BusinessIntelligence";
import BusinessCommunication from "pages/BusinessCommunication";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

export default function App() {
  return (
    <Router>
      <Switch>
        <Route path="/rpa">
          <RPA />
        </Route>
        <Route path="/dev-software">
          <DevSoftware />
        </Route>
        <Route path="/data-analytics">
          <DataAnalytics />
        </Route>
        <Route path="/portfolio-management">
          <PortfolioManagement />
        </Route>
        <Route path="/business-intelligence">
          <BusinessIntelligence />
        </Route>
        <Route path="/business-communication">
          <BusinessCommunication />
        </Route>
      </Switch>
    </Router>
  );
}
