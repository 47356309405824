//#region Imports

import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {Container as ContainerBase } from "components/misc/Layouts.js"
import defaultLogo from "../../images/logo.svg";
import { ReactComponent as FacebookIcon } from "../../images/icons/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "../../images/icons/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "../../images/icons/youtube-icon.svg";

//#endregion

//#region Components

const Container = tw(ContainerBase)`bg-gray-900 text-gray-100 -mx-8 -mb-8`
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const Row = tw.div`flex items-center justify-center flex-col px-8`
const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-2xl font-black tracking-wider`;
const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`;
const SocialLinksContainer = tw.div`mt-10`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`;
const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`

//#endregion

export default ({ socialLink = true, links, copyright, logo }) => {

  const defaultLinks = [
    {
      name: "Home",
      link: "#",
    },
    {
      name: "About",
      link: "#",
    },
    {
      name: "Contact Us",
      link: "#",
    },
    {
      name: "Blog",
      link: "#",
    },
    {
      name: "Reviews",
      link: "#",
    }
  ]

  const defaultCopyright = "Copyright 2020, Treact Inc. All Rights Reserved.";

  logo = logo || defaultLogo;
  links = links || defaultLinks;
  copyright = copyright || defaultCopyright;

  let url = window.location.href.split('#')[0].substring(window.location.href.lastIndexOf('/'));
  
  return (
    <Container style={{backgroundColor: "#000"}}>
      <Content>
        <Row>
          <LogoContainer>
            <LogoImg style={{width: "10rem", margin: "-2rem 0"}} src={logo} />
            { !logo && <LogoText>Treact</LogoText> }
          </LogoContainer>
          <LinksContainer>
          {
            links.map(({name, link}) => {
              return <Link key={name} href={url + link}>{name}</Link>
            })
          }
          </LinksContainer>
          {
            socialLink &&
              <SocialLinksContainer>
                <SocialLink href="https://facebook.com">
                  <FacebookIcon />
                </SocialLink>
                <SocialLink href="https://twitter.com">
                  <TwitterIcon />
                </SocialLink>
                <SocialLink href="https://youtube.com">
                  <YoutubeIcon />
                </SocialLink>
              </SocialLinksContainer>
          }
          <CopyrightText>
          &copy; {copyright}
          </CopyrightText>
        </Row>
      </Content>
    </Container>
  );
};
