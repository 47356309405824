//#region Imports
import "slick-carousel/slick/slick.css";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import loveIllustrationImageSrc from "images/love-illustration.svg";
import { ReactComponent as ArrowLeftIcon } from "images/arrow-left-3-icon.svg";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-3-icon.svg";
//#endregion

//#region Components
const Row = tw.div`flex flex-col md:flex-row justify-between items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const TextColumn = styled(Column)(props => [
  tw`mt-16 md:mt-0`
]);

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-6 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const TestimonialSlider = styled(Slider)`
  ${tw`w-full mt-10 text-center md:text-left`}
  .slick-track {
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex mb-1`}
  }
`;

const Testimonial = tw.div`outline-none h-full flex! flex-col`;
const TestimonialHeading = tw.div`mt-4 text-xl font-bold`;
const Quote = tw.blockquote`mt-4 mb-8 sm:mb-10 leading-relaxed font-medium text-gray-700`;

const CustomerInfoAndControlsContainer = tw.div`mt-auto flex justify-between items-center flex-col sm:flex-row`;

const CustomerInfo = tw.div`flex flex-col sm:flex-row items-center justify-center lg:justify-start`;
const CustomerName = tw.h5`font-bold text-xl`;
const CustomerTitle = tw.p`font-medium text-secondary-100`;

const Controls = styled.div`
  ${tw`flex mt-8 sm:mt-0`}
  .divider {
    ${tw`my-3 border-r`}
  }
`;
const ControlButton = styled.button`
  ${tw`mx-3 p-4 rounded-full transition duration-300 bg-gray-200 hover:bg-gray-300 text-primary-500 hover:text-primary-700 focus:outline-none focus:shadow-outline`}
  svg {
    ${tw`w-4 h-4 stroke-3`}
  }
`;
const ReadeMoreButton = styled.button`
  color: #000;
  margin-bottom: 1rem;
  font-size: 0.8rem;
  transition: 0.5s ease;
  width: fit-content;
  align-self: center;
  &:hover {
    color: #efefef;
    background: #1a788f;
    font-weight: bold;
    transition: 0.5s ease;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
  }
`

const GradientDiv = styled.div`
  position: relative;
  bottom:0px;
  left:0px;
  width:100%;
  height: 5rem;
  background: linear-gradient(to bottom,  rgba(137,255,241,0) 0%, rgb(255, 255, 255) 100%);
  ${props => props.display === "collapse" && css`
    visibility: collapse;
  `}
  ${props => props.display === "visible" && css`
    margin-top: -80px;
    visibility: visible;
  `}
`
//#endregion

const collapsed = { visibility: "collapse", height: 0, marginTop: 0 };
const visible = { visibility: "visible", height: "5rem"};

const TestimonialDescription = ({testimonial, styles, topMargin, sliderRef}) => {
  const [readMore, setReadMore] = useState([]);
  const [description, setDescription] = useState("");
  const [btnText, setBtnText] = useState("Continuar lendo");
  const [withBtn, setWithBtn] = useState("collapse");
  const [withGradient, setWithGradient] = useState(collapsed);
  const maxString = 250;

  useEffect(() => {
    if (testimonial.quote.length > maxString){
      setDescription(testimonial.quote.substring(0, maxString) + "...");
      setWithBtn("visible");
      setWithGradient(visible);
    }
    else {
      setDescription(testimonial.quote);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testimonial.id, testimonial.quote])

  const removeFromList = (id, value) => {
    readMore.pop(id);
    setWithGradient(visible);
    setDescription(value.substring(0, maxString) + "...");
    setBtnText("Continuar lendo");
    setReadMore(readMore)
  }

  const insertInList = (id, value) => {
    readMore.push(id);
    setWithGradient(collapsed);
    setDescription(value);
    setBtnText("Recolher");
    setReadMore(readMore)
  }

  const setDescriptionValue = (name, value) => {
    if (readMore.includes(name)) removeFromList(name, value);
    else insertInList(name, value);
  }

  const onClick = (ev) => {
    const { name, value } = ev.target;
    setDescriptionValue(name, value);
  }

  const prevClick = (ev) => {
    sliderRef?.slickPrev();
    if (btnText === "Recolher") removeFromList(testimonial.id, testimonial.quote);
  }

  const nextClick = (ev) => {
    sliderRef?.slickNext();
    if (btnText === "Recolher") removeFromList(testimonial.id, testimonial.quote);
  }

  return (
    <>
      <>
        <div>
          <Quote style={styles.quote}>{description}</Quote>
          <GradientDiv style={withGradient} display={withBtn}/>
        </div>
        <ReadeMoreButton 
          style={{visibility: withBtn }} 
          name={testimonial.id} 
          value={testimonial.quote} 
          onClick={onClick}>{btnText}</ReadeMoreButton>
      </>
      <div style={styles.divTags}>
        <p style={styles.pTitle}>CATEGORIAS</p>
        {testimonial.categories.map((category, index) => (
          <div key={index} style={styles.tagCategory}>{category}</div>
        ))}
      </div>
      <div style={styles.divTags}>
        <p style={styles.pTitle}>TECNOLOGIAS E FERRAMENTAS</p>
        {testimonial.tecnologies.map((tecnology, index) => (
          <div key={index} style={styles.tagTechnology}>{tecnology}</div>
        ))}
      </div>
      <CustomerInfoAndControlsContainer style={topMargin}>
        <CustomerInfo>
          <div>
            <CustomerName style={styles.title}>{testimonial.customerName}</CustomerName>
            <CustomerTitle style={styles.paragraph}>{testimonial.customerTitle}</CustomerTitle>
          </div>
        </CustomerInfo>
        <Controls>
          <ControlButton name={testimonial.id} value={testimonial.quote} onClick={prevClick}>
            <ArrowLeftIcon />
          </ControlButton>
          <div className="divider" />
          <ControlButton name={testimonial.id} value={testimonial.quote} onClick={nextClick}>
            <ArrowRightIcon />
          </ControlButton>
        </Controls>
      </CustomerInfoAndControlsContainer>
    </>
  );
}

export default ({
  imageSrc = loveIllustrationImageSrc,
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  subheading = "Testimonials",
  heading = "Our Clients Love Us.",
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua enim ad minim veniam.",
  textOnLeft = false,
  testimonials = [
    {
      profileImageSrc:
        "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
      heading: "Amazing User Experience",
      quote:
        "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
      customerName: "Charlotte Hale",
      customerTitle: "CEO, Delos Inc."
    },
    {
      profileImageSrc:
        "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
      heading: "Love the Developer Experience and Design Principles !",
      quote:
        "Sinor Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
      customerName: "Adam Cuppy",
      customerTitle: "Founder, EventsNYC"
    }
  ],
  styles
}) => {
  const [sliderRef, setSliderRef] = useState(null);
  const topMargin = {marginTop: "1rem"};

  return (
    <Container>
      <ContentWithPaddingXl>
        <Row>
          <TextColumn textOnLeft={textOnLeft}>
            <Subheading>{subheading}</Subheading>
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            <TestimonialSlider style={topMargin} arrows={false} ref={setSliderRef}>
              {testimonials.map((testimonial, index) => (
                <Testimonial key={index}>
                  <TestimonialHeading style={styles.title}>{testimonial.heading}</TestimonialHeading>
                  <TestimonialHeading style={styles.subtitle}>{testimonial.subheading}</TestimonialHeading>
                  <TestimonialDescription testimonial={testimonial} styles={styles} topMargin={topMargin} sliderRef={sliderRef}/>
                </Testimonial>
              ))}
            </TestimonialSlider>
          </TextColumn>
        </Row>
      </ContentWithPaddingXl>
    </Container>
  );
};
