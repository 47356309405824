//#region Imports

import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

//#endregion

//#region Icons
import defaultCardImage from "images/icons/shield-icon.svg";
import SupportIconImage from "images/icons/support-icon.svg";
import ShieldIconImage from "images/icons/shield-icon.svg";
import CustomizeIconImage from "images/icons/customize-icon.svg";
import FastIconImage from "images/icons/fast-icon.svg";
import ReliableIconImage from "images/icons/reliable-icon.svg";
import SimpleIconImage from "images/icons/simple-icon.svg";
//#endregion

//#region Components

const Container = tw.div`relative bg-primary-900 -mx-8 px-8 text-gray-100`;
const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4 text-gray-100`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center text-gray-300`;
const VerticalSpacer = tw.div`mt-10 w-full`;
const Card = styled.div`
  ${tw`flex flex-col items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`bg-gray-100 text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`mt-6`}
  }

  .title {
    ${tw`tracking-wider font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-2 font-normal text-gray-400 leading-snug`}
  }
`;

//#endregion

const style = {
 color: "#fff"
}

export default ({
  cards = null,
  heading = "Amazing Features",
  subheading = "",
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
}) => {

  const Column = (cards !== null && (cards.length % 3 === 0 || cards.length % 3 === 2))
    ? styled.div`${tw`md:w-1/2 lg:w-1/3 max-w-sm`}`
    : styled.div`${tw`md:w-1/2 lg:w-1/2 max-w-sm`}`;

  const defaultCards = [
    {
      imageSrc: ShieldIconImage,
      title: "Secure",
      description: "We strictly only deal with vendors that provide top notch security infrastructure."
    },
    { imageSrc: SupportIconImage, title: "24/7 Support" },
    { imageSrc: CustomizeIconImage, title: "Customizable" },
    { imageSrc: ReliableIconImage, title: "Reliable" },
    { imageSrc: FastIconImage, title: "Fast" },
    { imageSrc: SimpleIconImage, title: "Easy" }
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        {subheading && <Subheading style={style}>{subheading}</Subheading>}
        <Heading style={style}>{heading}</Heading>
        {description && <Description style={style}>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description" style={style}>
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
    </Container>
  );
};
