//#region Imports

import React from "react";
import optimizeImage from "images/bi/optimization-bi.jpg";
import operationImage from "images/bi/operation-bi.jpg";
import headerImage from "../images/bi/hero-bi.jpg";
import testimonialImage from "../images/cases.jpg"
import ComercialLandingPage from "demos/ComercialLandingPage";

//#endregion

//#region Icons

import Icon1 from "images/icons/increase.svg";
import Icon2 from "images/icons/file-block.svg";
import Icon3 from "images/icons/coin-down.svg";
import Icon4 from "images/icons/fast-icon.svg";
import Icon5 from "images/icons/insight.svg";

//#endregion

const cards = [
  {
    imageSrc: Icon1,
    title: "Aumento das vendas",
    description: "As ferramentas de BI dão acesso a melhores indicadores sobre as principais demandas dos clientes e sobre quais destas ainda não são atendidas. Assim, você será capaz de aumentar as suas vendas.",
    style: {  transform: "scale(1.3)" }
  },
  { 
    imageSrc: Icon2, 
    title: "Maior controle sobre riscos",
    description: "O BI entrega informações à sua empresa que ajudam a prevenir ameaças que possam se tornar crises futuramente.",
    style: { transform: "scale(1.3)" }
  },
  { 
    imageSrc: Icon3, 
    title: "Redução de custos",
    description: "A utilização de BI na sua empresa permite que você tenha uma visão mais clara sobre o efeito de cada recurso na empresa. Assim, ficará mais fácil usar menos recursos sem perder a eficiência.",
    style: { transform: "scale(1.3)" }
  },
  { 
    imageSrc: Icon4, 
    title: "Otimização das rotinas internas",
    description: "As ferramentas de BI entregam ao gestor indicadores que permitem a avaliação de desempenho de cada setor da cadeia operacional. Desse modo, tem-se a fundentação necessária para tonar os processos e atividades na empresa mais eficientes.",
    style: { transform: "scale(1.3)" }
  },
  { 
    imageSrc: Icon5, 
    title: "Identificação de oportunidades",
    description: "O BI, ao fornecer os dados e indicadores resultantes da análise do mercado, permite um melhor entendimento do mercado. Dessa forma, torna-se mais fácil para a empresa identificar e  aproveitar as oportunidades que apareçam.",
    style: { transform: "scale(1.3)" }
  }
];

export default () => {
  return (
    <ComercialLandingPage 
      headerOne={<>Business<br/></>}
      headerTwo={"Intelligence"}
      headerParagraph={"Nós captamos e analisamos dados, apoiando as decisões estratégicas da sua empresa."}
      headerImage={headerImage}
      optimizeOne={"Por que o serviço pode"}
      optimizeTwo={"melhorar a empresa?"}
      optimizeParagraph={"As ferramentas de business intelligence simplificam e aceleram a visualização de análise de informações. Assim, auxiliamos sua empresa a tomar decisões embasadas mais rápidas e eficientes e possibilitamos que problemas sejam solucionados antecipadamente, uma vez que grandes quantias de dados são traduzidos em interpretações que são importantes para a empresa."}
      optimizeImage={optimizeImage}
      benefitHeader={"Alguns benefícios do Business Intelligence"}
      benefitDescription={
        <>
          Segundo o <strong>Markets and Markets</strong>, é previsto que o mercado global de BI expanda de US$ 23,1 bilhões em 2020 para US$ 33,3 bilhões em 2025.
        </>
      }
      operationOne={"Como o serviço"}
      operationTwo={"funciona?"}
      operationParagraph={"O business intelligence é um processo tecnológico para análise de dados e apresentação de informações, as quais são usualmente direcionadas para os meios de tomada de decisão da empresa. Desse modo, este processo pode ser dividido em 4 etapas: coleta das informações, análise dos dados coletados, montagem de relatórios de BI para a visualização dos dados e, por fim, atualização das fontes de dados - para ter constante validação das informações coletadas. Assim, com o compartilhamento dos insights produzidos, facilita-se a tomada de medidas pela empresa."}
      operationImage={operationImage}
      cards={cards}
      pageName={"Business Intelligence"}
      testimonialImage={testimonialImage}
    />
  );
}