//#region Imports

import React from "react";
import optimizeImage from "images/rpa/optimization.jpg";
import operationImage from "images/rpa/operation.jpg";
import headerImage from "../images/rpa/hero.jpg";
import testimonialImage from "../images/cases.jpg"
import ComercialLandingPage from "demos/ComercialLandingPage";

//#endregion

//#region Icons

import CoinUp from "images/icons/coin-up.svg";
import CoinDown from "images/icons/coin-down.svg";
import Speed from "images/icons/speed.svg";
import Failure from "images/icons/failure.svg";

//#endregion

const cards = [
  {
    imageSrc: CoinDown,
    title: "Redução dos custos operacionais",
    description: "A automatização permite a redução da mão de obra e da duplicidade de trabalho. Desse modo, reduz-se significativamente o custo operacional",
    style: {  transform: "scale(1.3)" }
  },
  { 
    imageSrc: CoinUp, 
    title: "Maiores rendimentos",
    description: "A automação permite que muitas tarefas sejam realizadas em segundos, permitindo entrega valor ao cliente de modo mais rápido.",
    style: { transform: "scale(1.3)" }
  },
  { 
    imageSrc: Speed, 
    title: "Ganho de agilidade",
    description: "Com a automatização, a operação passa a ser realizada 24 horas por dia, 7 dias por semana, ou seja, de forma ininterrupta.",
    style: { transform: "scale(1.3)" }
  },
  { 
    imageSrc: Failure, 
    title: "Redução dos erros humanos",
    description: "A RPA possibilita que os erros causados pela repetição frequente de tarefas manuais sejam reduzidos a quase zero.",
    style: { transform: "scale(1.3)" }
  },
];

export default () => {
  return (
    <ComercialLandingPage 
      headerOne={"Automação Robótica de"}
      headerTwo={"Processos"}
      headerParagraph={"Nós automatizamos tarefas repetitivas com o uso de softwares RPA, eliminando erros manuais na sua empresa."}
      headerImage={headerImage}
      optimizeOne={"Por que o serviço pode"}
      optimizeTwo={"melhorar a empresa?"}
      optimizeParagraph={"A Automação Robótica de Processos (RPA) possibilita o alcance máximo de eficiência ao eliminar processos repetitivos do fluxo de trabalho. Assim, permite-se que o tempo seja mais bem investido em outros processos, uma vez que seus funcionários ficarão menos presos a tarefas repetitivas e serão mais livres para executar trabalhos analíticos e que exijam raciocínio crítico e lógico."}
      optimizeImage={optimizeImage}
      benefitHeader={"Alguns benefícios do RPA"}
      benefitDescription={
        <>
          90% das grandes organizações globais terão implantado, até o fim de 2022, alguma forma de atuação de RPA.<br/><strong>GARTNER</strong>
        </>
      }
      operationOne={"Como o serviço"}
      operationTwo={"funciona?"}
      operationParagraph={"Robotic Process Automation, ou melhor, RPA, é uma tecnologia que tem como objetivo a automação de processos repetitivos por meio da utilização de robôs (os bots). Esses robôs são controlados por códigos de computador pré-programados, de modo que são capazes tanto de executar ações programadas como de aprimorar seu algoritmo com novas funções."}
      operationImage={operationImage}
      cards={cards}
      pageName={"Automação Robótica de Processos"}
      testimonialImage={testimonialImage}
    />
  );
}
