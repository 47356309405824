import img0 from "./client0.png";
import img1 from "./client1.png";
import img2 from "./client2.png";
import img3 from "./client3.png";
import img4 from "./client4.png";
import img5 from "./client5.png";
import img6 from "./client6.png";
import img7 from "./client7.png";
import img8 from "./client8.png";
import img9 from "./client9.png";
import img10 from "./client10.png";
import img11 from "./client11.png";
import img12 from "./client12.png";
import img13 from "./client13.png";
import img14 from "./client14.png";
import img15 from "./client15.png";
import img16 from "./client16.png";
import img17 from "./client17.png";
import img18 from "./client18.png";
import img19 from "./client19.png";
import img20 from "./client20.png";
import img21 from "./client21.png";
import img22 from "./client22.png";
import img23 from "./client23.png";
import img24 from "./client24.png";
import img25 from "./client25.png";
import img26 from "./client26.png";
import img27 from "./client27.png";
import img28 from "./client28.png";
import img29 from "./client29.png";
import img30 from "./client30.png";

const logos = [
  img0,
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
  img19,
  img20,
  img21,
  img22,
  img23,
  img24,
  img25,
  img26,
  img27,
  img28,
  img29,
  img30
]

export default { logos }