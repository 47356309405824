//#region Imports

import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Beneficios from "components/features/ThreeColWithSideImageWithPrimaryBackground.js";
import Funcionamento from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import Sobre from "components/features/TwoColWithButton";
import Casos from "components/testimonials/TwoColumnWithImageAndButton";
import Footer from "components/footers/MiniCenteredFooter.js";
import Cta from "components/cta/GetStarted.js";
import logoBranca from "../images/mstar-logo-branco.png";
import dataCases from "../data/cases.json";

//#endregion

//#region Logos

import frgLogo from "../images/logos/frg.svg";
import makrofarmaLogo from "../images/logos/makrofarma.png";
import petrobrasLogo from "../images/logos/petrobras-sem-texto.png";

//#endregion

//#region Global Scope

const defaultLinks = [
  {
    name: "Otimização",
    link: "#otimizacao",
  },
  {
    name: "Benefícios",
    link: "#beneficios",
  },
  {
    name: "Funcionamento",
    link: "#funcionamento",
  },
  {
    name: "Casos",
    link: "#casos",
  }
]
const mainUrl = "https://www.morningstar.com.br/";
const contactUrl = "https://www.morningstar.com.br/Home/Fale";

const styles = {
  heading: {
    backgroundColor: "#1a788f ", 
    padding: "0.15rem 0.5rem", 
    color: "#fff",
  },
  title: {
    color: "#1a788f",
  },
  subtitle: {
    color: "#000",
    fontWeight: "700",
    fontSize: "1.2rem",
  },
  paragraph: {
    color: "#000", 
    fontWeight: "600"
  },
  pTitle: {
    color: "#000", 
    fontWeight: "600",
    marginBottom: "0.5rem"
  },
  quote: {
    color: "#000", 
    fontWeight: "600",
    marginBottom: "1rem"
  },
  black: {
    color: "#000",
  },
  tagCategory: {
    fontSize: "0.8rem",
    backgroundColor: "#3c9e78",
    padding: "0.25rem 0.5rem",
    borderRadius: "0.5rem",
    color: "#fff",
    margin: "0 0.3rem 0.3rem 0",
    display: "inline-block",
    boxShadow: "10px 10px"
  },
  tagTechnology: {
    fontSize: "0.8rem",
    backgroundColor: "#1a788f",
    padding: "0.25rem 0.5rem",
    borderRadius: "0.5rem",
    color: "#fff",
    margin: "0 0.3rem 0.3rem 0",
    display: "inline-block",
    boxShadow: "10px 10px"
  },
  divTags: {
    marginBottom: "2rem"
  },
  readMore: {
    color: "#000",
    marginBottom: "1rem",
    fontSize: "0.8rem",
    "&:hover": {
      color: "#efefef",
      background: "#1a788f"
    },
  }
}

const DefaultTitle = ({partOne, partTwo}) => {
  return (
    <>
      <span style={styles.black}>{partOne}</span> <span style={styles.heading}>{partTwo}</span>
    </>
  );
}

const DefaultParagraph = ({text}) => {
  return (
    <span style={styles.paragraph}>{text}</span>
  );
}

//#endregion

function CapitalizeText(str) {
  return str.split(" ").map((word) => { 
    return word[0].toUpperCase() + word.substring(1).toLowerCase(); 
  }).join(" ");
}

export default ({
  headerOne,
  headerTwo,
  headerParagraph,
  headerImage,
  optimizeOne,
  optimizeTwo,
  optimizeParagraph,
  optimizeImage,
  benefitHeader,
  benefitDescription,
  operationOne,
  operationTwo,
  operationParagraph,
  operationImage,
  cards,
  links,
  pageName,
  testimonialImage
}) => {
  const [cases, setCases] = useState([]);
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;

  links = links !== undefined ? links : defaultLinks;

  useEffect(() => {
    if (pageName !== undefined) {
      let array = [];
      let row;
      for (let i = 0; i < dataCases.length; i++) {
        row = dataCases[i];
        if (row.paginas.includes(pageName)) {
          let image;
          switch (row.cliente) {
            case "Petrobrás":
              image = petrobrasLogo;
              break;
            case "FRG":
              image = frgLogo;
              break;
            default:
              image = makrofarmaLogo;
              break;
          }

          let categories = row.categorias.split(";").map((x) => { return x });
          let tecnologies = row.tecnologias.split(";").map((x) => { return x });

          array.push({
            id: i,
            profileImageSrc: image,
            heading: row.titulo,
            subheading: row.segmento,
            quote: row.descricao,
            customerName: row.gerencia,
            customerTitle: CapitalizeText(row.industria),
            categories: categories,
            tecnologies: tecnologies
          });
        }
      }
      setCases(array);
    }
  }, [pageName])

  return (
    <AnimationRevealPage disabled>
      <section id="header">
        <Hero 
          roundedHeaderButton={true}
          heading={
            <DefaultTitle 
              partOne={headerOne}
              partTwo={headerTwo}
            />
          }
          paragraph={
            <DefaultParagraph text={headerParagraph}/>
          }
          DesignIllustration={headerImage}
          contactUrl={contactUrl}
          mainUrl={mainUrl}
        />
      </section>

      <section id="otimizacao">
        <Sobre
          subheading={<Subheading>OTIMIZAÇÃO</Subheading>}
          heading={
            <DefaultTitle 
              partOne={optimizeOne}
              partTwo={optimizeTwo}
            />
          }
          description={
            <DefaultParagraph text={optimizeParagraph}/>
          }
          primaryButtonText={"Saiba Mais"}
          primaryButtonUrl={mainUrl}
          textOnLeft={false}
          imageSrc={optimizeImage}
          imageDecoratorBlob={true}
          decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
        />
      </section>

      <section id="beneficios">
        <Beneficios
          cards={cards}
          description={benefitDescription}
          heading={benefitHeader}
        />
      </section>

      <section id="funcionamento">
        <Funcionamento
          subheading={<Subheading>FUNCIONAMENTO</Subheading>}
          heading={
            <DefaultTitle 
              partOne={operationOne}
              partTwo={operationTwo}
            />
          }
          description={
            <DefaultParagraph text={operationParagraph}/>
          }
          imageSrc={operationImage}
          showDecoratorBlob={false}
          primaryButtonText="Saiba Mais"
          primaryButtonUrl={mainUrl}
          features={[]}
        />
      </section>

      <section id="casos">
        <Casos 
          imageSrc={testimonialImage}
          imageRounded={true}
          imageBorder={true}
          imageShadow={true}
          subheading="CASOS"
          heading={
            <DefaultTitle 
              partOne={"Alguns dos nossos"}
              partTwo={"projetos"}
            />
          }
          testimonials={cases}
          description={""}
          textOnLeft={false}
          styles={styles}
        />
      </section>

      <section id="cta">
        <Cta 
          text={"Tenha auxílio de especialistas para que seus objetivos sejam alcançados."}
          primaryLinkText={"Entre em Contato"}
          primaryLinkUrl={contactUrl}
          secondaryLinkText={"Saiba Mais"}
          secondaryLinkUrl={mainUrl}
        />
      </section>

      <section id="footer">
        <Footer 
          socialLink={false} 
          links={defaultLinks} 
          copyright={"Copyright 2003 Morning Star | All Rights Reserved"}
          logo={logoBranca}
        />
      </section>
    </AnimationRevealPage>
  );
}
