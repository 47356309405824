//#region Imports

import React from "react";
import optimizeImage from "images/dev-software/optimization.jpg";
import operationImage from "images/dev-software/operation.jpg";
import headerImage from "../images/dev-software/hero.jpg";
import testimonialImage from "../images/cases.jpg"
import ComercialLandingPage from "demos/ComercialLandingPage";

//#endregion

//#region Icons

import CoinUp from "images/icons/coin-up.svg";
import Speed from "images/icons/speed.svg";
import ShieldIconImage from "images/icons/data-protection.svg";
import ReliableIconImage from "images/icons/increase.svg";

//#endregion

const cards = [
  {
    imageSrc: Speed,
    title: "Aumento da produtividade",
    description: "O software personalizado permite que sua empresa concentre tudo que é necessário em um só lugar, o que possibilita um melhor fluxo de trabalho e maior confiança dos funcionários.",
    style: {  transform: "scale(1.3)" }
  },
  { 
    imageSrc: ReliableIconImage, 
    title: "Evolução junto com a empresa",
    description: "É natural que sua empresa cresça e necessite de novos módulos. Diante disso, com o software personalizado, é simples adaptá-los ao seu sistema.",
    style: { transform: "scale(1.3)" }
  },
  { 
    imageSrc: CoinUp, 
    title: "Maior retorno sobre o investimento",
    description: "Os sistemas sob medida oferecem as funcionalidades específicas para o que sua empresa precisa. Assim, não haverá o gasto com softwares complementares.",
    style: { transform: "scale(1.3)" }
  },
  { 
    imageSrc: ShieldIconImage, 
    title: "Informações mais seguras",
    description: "Os software sob demanda permitem que a implantação de um sistema tenha o acesso controlado para pessoas autorizadas,  aumentando a segurança dos dados da empresa e de funcionários. Além disso, é possível integrar módulos adicionais de segurança ao sistema.",
    style: { transform: "scale(1.3)" }
  },
];

export default () => {
  return (
    <ComercialLandingPage 
      headerOne={"Softwares Sob "}
      headerTwo={"Demanda"}
      headerParagraph={"Nós desenvolvemos sistemas específicos, atendendo às necessidades da sua empresa."}
      headerImage={headerImage}
      optimizeOne={"Por que o serviço pode"}
      optimizeTwo={"melhorar a empresa?"}
      optimizeParagraph={"Proporcionamos um desenvolvimento personalizado de sistemas baseado nas necessidades que a empresa possui. Assim garante-se precisão e detalhamento na solução oferecida, uma vez que sua empresa interage durante o projeto e são implantadas resoluções que não são oferecidas por produtos disponíveis no mercado."}
      optimizeImage={optimizeImage}
      benefitHeader={"Alguns benefícios do Software Sob Demanda"}
      benefitDescription={
        <>
          O software personalizado traz um fator competitivo relevante, podendo ser um diferencial de valor entre você e seu concorrente.<br/><strong>ABES - Associação Brasileira de Empresas </strong>
        </>
      }
      operationOne={"Como o serviço"}
      operationTwo={"funciona?"}
      operationParagraph={"O software sob demanda - ou personalizado - é uma solução desenhada especificamente para cada cliente e problema apresentado. Para desenvolvimento desses sistemas, podemos dividir o desdobramento do projeto em 4 etapas: levantamento de requisitos e entendimento do problema a ser resolvido, definição dos primeiros entregáveis, desenvolvimento do software e, por fim, validação do projeto."}
      operationImage={operationImage}
      cards={cards}
      pageName={"Softwares Sob Demanda"}
      testimonialImage={testimonialImage}
    />
  );
}