//#region Imports

import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import Header from "../headers/light.js";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import Slider from "components/misc/Slider/index.js";

//#endregion

//#region Components
const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
const LeftColumn = tw.div`relative lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`;
const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;
const Button = styled.button`
  ${tw`w-full sm:block bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
`;
const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;
const CustomersLogoStrip = styled.div`
  ${tw`mt-8 lg:mt-8`}
  p {
    ${tw`uppercase text-sm lg:text-xs tracking-wider font-bold text-gray-500`}
  }
  img {
    ${tw`mt-4 w-full lg:pr-16 xl:pr-32 opacity-75`}
  }
`;
//#endregion

export default ({ 
  roundedHeaderButton, links, contactUrl, mainUrl, DesignIllustration, heading, paragraph 
}) => {

  return (
    <>
      <Header 
        roundedHeaderButton={roundedHeaderButton} 
        links={links}
        contactUrl={contactUrl}
        mainUrl={mainUrl} />
      <Container>
        <TwoColumn>
          <LeftColumn>
            <Heading>
              {heading}
            </Heading>
            <Paragraph>
              {paragraph}
            </Paragraph>
            <Button onClick={() => window.open(mainUrl, "_blank")}>Saiba mais</Button>
            <CustomersLogoStrip>
              <p style={{color: "#000"}}>Nossos Clientes</p>
              <Slider />
            </CustomersLogoStrip>
          </LeftColumn>
          <RightColumn>
            <IllustrationContainer>
              <img tw="min-w-0 w-full max-w-lg xl:max-w-3xl" src={DesignIllustration} style={{borderRadius: "1.5%", boxShadow: "0.5rem 1rem 1rem #ccc"}} alt="Design Illustration" />
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
        <DecoratorBlob1 />
      </Container>
    </>
  );
};
