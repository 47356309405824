import clients from 'images/clients/logos'
import React from 'react'
import "./styles.css"

export default function Slider() {
  return (
    <div className="slider-body">
      <div className="row">
        <div className="col-12">
          <div className="slider">
            <div className="slide-track">
              {
                clients.logos.map((res, index) => {
                  return (
                    <div key={index} className="slide">
                      <img className='slide-img' src={res} alt="" width="250px" height="100px" />
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
