//#region Imports

import React from "react";
import optimizeImage from "images/data-analytics/about-da.jpg";
import operationImage from "images/data-analytics/operation-da.jpg";
import headerImage from "../images/data-analytics/header-da.jpg";
import testimonialImage from "../images/cases.jpg"
import ComercialLandingPage from "demos/ComercialLandingPage";

//#endregion

//#region Icons

import Icon1 from "images/icons/marketing.svg";
import Icon2 from "images/icons/customers.svg";
import Icon3 from "images/icons/preview-2.svg";
import Icon4 from "images/icons/shield-icon.svg";

//#endregion

const cards = [
  {
    imageSrc: Icon1,
    title: "Marketing mais efeiciente",
    description: "Nossos serviços de Data Analytics podem ser usados para identificar e reconhecer padrões de comportamento. Isso permite desde uma otimização do produto entregue ao consumidor até a transformação do Marketing Digital na sua empresa, sendo possível melhorar o rankeamento da sua empresa em mecanismos de busca e a estratégia para comunicação nas redes sociais, por exemplo. Assim, é esperado que seu Marketing possua um ROI (Retorno sobre o Investimento) muito maior.",
    style: {  transform: "scale(1.3)" }
  },
  { 
    imageSrc: Icon2, 
    title: "Retenção de clientes",
    description: "Com a aplicação de Data Analytics, é possível entender melhor quais são as preferências de seus clientes. Isso permite, por exemplo, o envio de newsletters personalizadas e o aprimoramento de produtos e serviços. Dessa forma, aumenta-se a chance de retenção dos seus clientes.",
    style: { transform: "scale(1.3)" }
  },
  { 
    imageSrc: Icon3, 
    title: "Melhores previsões de demanda",
    description: "Os serviços que oferecemos de Data Analytics podem, também, identificar tendências dentro da sua empresa. Isso permite que alguns processos possam ser otimizados, como a previsão de demandas da sua organização.",
    style: { transform: "scale(1.3)" }
  },
  { 
    imageSrc: Icon4, 
    title: "Redução de riscos",
    description: "A coleta e a análise precisa de dados, presente nos serviços de Data Analytics, permite que  os problemas dentro da sua empresa sejam mais facilmente detectados. Desse modo, muitos riscos são evitados, como pela detecão de fraudes e de problemas internos que retardam ou impedem a produção, por exemplo.",
    style: { transform: "scale(1.3)" }
  },
];

export default () => {
  return (
    <ComercialLandingPage 
      headerOne={"Data"}
      headerTwo={"Analytics"}
      headerParagraph={"Nós aplicamos o poder analítico da estatística e de algoritmos a grandes quantias de dados, auxiliando para melhores tomadas de decisões."}
      headerImage={headerImage}
      optimizeOne={"Por que o serviço pode"}
      optimizeTwo={"melhorar a empresa?"}
      optimizeParagraph={"Produzimos insights valiosos para a sua empresa. Assim, fornecemos à sua empresa ferramentas para resolver problemas desafiadores no seu negócio, uma vez que a aplicação de estatística e algoritmos aos dados coletados permite a realização de análises preditivas, o reconhecimento de padrões, análise descritivas e muitos outros produtos, aumentado o poder de tomada de decisão na sua empresa."}
      optimizeImage={optimizeImage}
      benefitHeader={"Alguns benefícios do Data Analytics"}
      benefitDescription={
        <>
          Segundo a <strong>Harvard Business Review</strong>, empresas que seguem um programa de Data Analytics são, em média, 5% mais produtivas e 6% mais lucrativas que suas concorrentes.
        </>
      }
      operationOne={"Como o serviço"}
      operationTwo={"funciona?"}
      operationParagraph={"Data Analytics, ou Análise de Dados, é um processo que, por meio da coleta e análise de dados objetiva descobrir informações úteis, fornecer conclusões e auxiliar as tomadas de decisões.  Desse modo, é possível dividir tal processo em quatro etapas: coleta de dados (utilizando técnicas como Web Scraping, por exemplo), processamento dos dados (por meio de filtragens, possibilitando uma melhor identificação dos dados), análise dos dados (utiliza-se algoritmos e estatística para obter conclusões sobre os dados) e, por fim, montagem de relatórios e gráficos."}
      operationImage={operationImage}
      cards={cards}
      pageName={"Data Analytics"}
      testimonialImage={testimonialImage}
    />
  );
}
