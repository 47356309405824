//#region Imports

import React from "react";
import optimizeImage from "images/bc/optimization-bc.jpg";
import operationImage from "images/bc/operation-bc.jpg";
import headerImage from "../images/bc/hero-bc.jpg";
import testimonialImage from "../images/cases.jpg"
import ComercialLandingPage from "demos/ComercialLandingPage";

//#endregion

//#region Icons

import Icon1 from "images/icons/hand-up.svg";
import Icon2 from "images/icons/communication.svg";
import Icon3 from "images/icons/reliable-icon.svg";

//#endregion

const cards = [
  {
    imageSrc: Icon1,
    title: "Melhor imagem da empresa",
    description: "A boa comunicação empresarial permite que sua empresa obtenha maior confiança dos stakeholders. Assim, o modo que sua organização é vista é melhorado também.",
    style: {  transform: "scale(1.3)" }
  },
  { 
    imageSrc: Icon2, 
    title: "Maior engajamento",
    description: "A boa comunicação permite que se tenha uma maior transparência da empresa. Dessa forma, isso motiva os stakeholders a participarem do desenvolvimento do negócio.",
    style: { transform: "scale(1.3)" }
  },
  { 
    imageSrc: Icon3, 
    title: "Maior credibilidade dos serviços",
    description: "A comunicação empresarial pode ser usada para informar sobre a boa qualidade dos serviços ou produtos oferecidos. Assim, o público interessado irá colocar mais confiança no desempenho da sua organização.",
    style: { transform: "scale(1.3)" }
  }
];

export default () => {
  return (
    <ComercialLandingPage 
      headerOne={<>Comunicação<br/></>}
      headerTwo={"Empresarial"}
      headerParagraph={"Nós avaliamos, planejamos e reorganizamos a comunicação e o relacionamento com os públicos de interesse, possibilitando trocas de informação direcionadas e segmentadas de forma clara e objetiva."}
      headerImage={headerImage}
      optimizeOne={"Por que o serviço pode"}
      optimizeTwo={"melhorar a empresa?"}
      optimizeParagraph={"Permitimos que a comunição da empresa com seus stakeholders ocorra de modo a minimizar os desvios na recepção e no entendimento. Assim, auxiliamos no desenvolvimento organizacional da empresa, uma vez que haverá maior eficiência no relacionamento da sua organização com o público interessado."}
      optimizeImage={optimizeImage}
      benefitHeader={"Alguns benefícios da Comunicação Empresarial"}
      benefitDescription={
        <>
          Uma pesquisa feita para <strong>International Stress Management Association (Isma-BR/2007)</strong>, indicou que a má gestão da comunicação empresarial apresenta um papel importante no ambiente  e consequentemente pode ocasionar interferências na produtividade da organização.
        </>
      }
      operationOne={"Como o serviço"}
      operationTwo={"funciona?"}
      operationParagraph={"A consultoria de Comunicação Empresarial visa organizar o relacionamento da empresa - ou de um setor específico desta - com os seus stakeholders. Desse modo, para que isso seja realizado, passamos por quatro etapas: levantamento e coleta de informações (onde são mapeados os principais pontos críticos da comunicação), apontamento de soluções e consultoria para as tomadas de decisões de ações relativas ao relacionamento, execução das ações e, por fim, monitoramento e avaliação das ações implementadas."}
      operationImage={operationImage}
      cards={cards}
      pageName={"Comunicação Empresarial"}
      testimonialImage={testimonialImage}
    />
  );
}