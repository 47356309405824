//#region Imports

import React from "react";
import optimizeImage from "images/pp/optimization-pp.jpg";
import operationImage from "images/pp/operation-pp.jpg";
import headerImage from "../images/pp/hero-pp.jpg";
import testimonialImage from "../images/cases.jpg"
import ComercialLandingPage from "demos/ComercialLandingPage";

//#endregion

//#region Icons

import Icon1 from "images/icons/increase.svg";
import Icon2 from "images/icons/communication.svg";
import Icon3 from "images/icons/planning.svg";
import Icon4 from "images/icons/schedule.svg";
import Icon5 from "images/icons/shield-icon.svg";
import Icon6 from "images/icons/monitor.svg";

//#endregion

const cards = [
  {
    imageSrc: Icon1,
    title: "Investimento melhor direcionado",
    description: "A gestão de portfólio permite que se tenha uma melhor organização de informações estratégicas dos projetos já realizados pela empresa. Assim, é possível utilizar esses dados para embasar tomadas de decisões relativas a novos projetos, reduzindo incertezas e riscos associados.",
    style: {  transform: "scale(1.3)" }
  },
  { 
    imageSrc: Icon2, 
    title: "Comunicação com stakeholders",
    description: "A gestão da carteira de projetos inclui a divulgação das informações obtidas sobre cada projeto ao público de interesse. Assim, permite-se que haja uma comunicação mais clara e objetiva com os stakeholders.",
    style: { transform: "scale(1.3)" }
  },
  { 
    imageSrc: Icon3, 
    title: "Alinhamento com o planejamento estratégico",
    description: "Com a gestão de portfólio na sua empresa, facilita-se a aplicação das definições do planejamento estratégico na execução dos projetos, garantindo, assim, um maior alinhamento.",
    style: { transform: "scale(1.3)" }
  },
  { 
    imageSrc: Icon4, 
    title: "Cumprimento do cronograma",
    description: "A gestão de projetos permite que sejam estabelecidos prazos realistas e que, devido ao acompanhamento do gestor de projetos, os atrasos sejam minimizados. Dessa forma, facilita-se que os projetos sejam entregues no prazo prometido ao cliente.",
    style: { transform: "scale(1.3)" }
  },
  { 
    imageSrc: Icon5, 
    title: "Redução de riscos",
    description: "Devido a grande coleta de informações e ao planejamento do projeto pelo gestor de projetos, a antecipação de riscos, assim como o monitoramento de alterações indesejadas, é facilitada.",
    style: { transform: "scale(1.3)" }
  },
  { 
    imageSrc: Icon6, 
    title: "Monitoramento dos lucros",
    description: "A gestão de projetos possibilita acompanhar quais foram os gastos no desenvolvimento do projeto. Assim, facilita-se o processo de cálculo do lucro que se obteve no referente projeto.",
    style: { transform: "scale(1.3)" }
  },
];

export default () => {
  return (
    <ComercialLandingPage 
      headerOne={<>Gestão de<br/></>}
      headerTwo={"Projetos e Portfólios"}
      headerParagraph={"Nós traçamos as melhores estratégias para que seus projetos sejam executados com a maior eficiência, garatindo que sua empresa alcance os resultados desejados."}
      headerImage={headerImage}
      optimizeOne={"Por que o serviço pode"}
      optimizeTwo={"melhorar a empresa?"}
      optimizeParagraph={"Possibilitamos que a sua empresa tenha uma melhor visão do potencial de cada projeto, além de garantirmos que este seja realizado com pontualidade e dentro do orçamento. Assim, auxiliamos sua empresa a alcançar os objetivos estratégicos, uma vez que haverá maior conformidade do planjemento e da execução dos projetos com os objetivos da empresa."}
      optimizeImage={optimizeImage}
      benefitHeader={"Alguns benefícios do Software Sob Demanda"}
      benefitDescription={
        <>
          Segundo o <strong>Global Megatrends 2022 do PMI</strong>, "A adoção de tecnologias digitais avançadas aumentará até 2026", com isso, a gestão de projetos e portfolio se tornará ainda mais essencial.
        </>
      }
      operationOne={"Como o serviço"}
      operationTwo={"funciona?"}
      operationParagraph={"Enquanto a gestão de portfólio se refere, resumidamente, ao gerenciamento e planejamento da execução de um conjunto de projetos, definindo a ordem de execução e as equipes e acompanhando os resultados destes, a gestão de projetos se preocupa com o andamento do projeto, aplicando metodologias para garantir que as metas deste sejam alcançadas. Para que esses processos de gerenciamento ocorram, utilizamos 4 etapas: diagnóstico situacional (coleta e análise de dados para conhecer a realidade da empresa), estratégia de atuação (definição de estratégias para resolução de problemas), consultoria especializada (assessoramento, identificação de problemas e proposição de soluções técnicas para situações específicas) e, por fim, análises gerenciais (análises baseadas no estudo de dados e cenários para melhorar e otimizar processos)."}
      operationImage={operationImage}
      cards={cards}
      pageName={"Gestão de Projetos e Portfólios"}
      testimonialImage={testimonialImage}
    />
  );
}